<template>
  <div class="px-3">
    <div class="form-group d-flex my-2 align-items-center row">
      <div class="col-12">
        <div class="d-flex">
          <div class="mr-3">
            <span class="editorStyleTitle">Show Label: </span>
          </div>
          <div class="custom-control custom-switch cursor-pointer">
            <input
              type="checkbox"
              class="custom-control-input"
              :id="`ShowLabel-${Date.now()}`"
              v-model="block.showLabel"
            />
            <label
              class="custom-control-label"
              :for="`ShowLabel-${Date.now()}`"
            ></label>
          </div>
        </div>
      </div>

      <div class="col-12 px-1 mt-2" v-if="block.showLabel">
        <div class="d-flex align-items-center mx-2">
          <span class="editorStyleTitle">Label text: </span>
          <input
            type="text"
            class="px-3 py-1 flex-grow-1 form-control-input"
            style="
              border: none;
              border-radius: 2px;
              outline: none;
              border-bottom: 1px solid #e0dee2;
            "
            v-model="block.labelText"
            placeholder="Choose the best oppurtunity..."
          />
        </div>
      </div>
    </div>

    <div class="form-group d-flex mb-2 align-items-center row">
      <div class="col-12">
        <div class="d-flex mt-2">
          <div class="mr-3">
            <span class="editorStyleTitle">Required: </span>
          </div>
          <div class="custom-control custom-switch cursor-pointer">
            <input
              type="checkbox"
              class="custom-control-input"
              :id="`customCheckRequired-${Date.now()}`"
              v-model="block.isRequired"
            />
            <label
              class="custom-control-label"
              :for="`customCheckRequired-${Date.now()}`"
            ></label>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group d-flex mb-3 align-items-center row">
      <div class="col-12 my-1">
        <div class="d-flex">
          <div class="mr-3">
            <span class="editorStyleTitle">Limit Text: </span>
          </div>

          <div class="custom-control custom-switch cursor-pointer">
            <input
              type="checkbox"
              class="custom-control-input"
              :id="`LimitFreeTextArea-Main-${Date.now()}`"
              v-model="block.limitFreeTextArea.limitFreeTextAreaValue"
            />
            <label
              class="custom-control-label"
              :for="`LimitFreeTextArea-Main-${Date.now()}`"
            ></label>
          </div>
        </div>
      </div>
      <div
        class="col-12 my-1"
        v-if="
          Object.prototype.hasOwnProperty.call(block, `limitFreeTextArea`) &&
          block.limitFreeTextArea.limitFreeTextAreaValue
        "
      >
        <div class="">
          <!-- Type Start -->
          <div class="d-flex align-items-center mt-2">
            <!-- <span>Type</span> -->
            <select
              class="form-select w-50 form-control-sm input-border-style pl-2 ml-2"
              aria-label="Default select example"
              v-model="block.limitFreeTextArea.limitSelectionType"
            >
              <option value="exact">Exact</option>
              <option value="min">Min</option>
              <option value="max">Max</option>
            </select>

            <div
              v-if="block.limitFreeTextArea.limitSelectionType == 'exact'"
              class=""
            >
              <div class="d-flex align-items-center">
                <input
                  type="number"
                  min="1"
                  v-model="block.limitFreeTextArea.exactFreeTextAreaValue"
                  class="form-control-sm w-100 px-2 ml-1 input-border-style"
                />
              </div>
            </div>
            <div
              v-if="block.limitFreeTextArea.limitSelectionType == 'min'"
              class="d-flex align-items-center"
            >
              <input
                type="number"
                min="1"
                v-model="block.limitFreeTextArea.minFreeTextAreaValue"
                class="form-control-sm w-100 px-2 ml-1 input-border-style mr-1"
              />
            </div>
            <div
              v-if="block.limitFreeTextArea.limitSelectionType == 'max'"
              class="d-flex align-items-center"
            >
              <input
                type="number"
                min="1"
                v-model="block.limitFreeTextArea.maxFreeTextAreaValue"
                class="form-control-sm w-100 px-2 ml-1 input-border-style mr-1"
              />
            </div>
          </div>
          <!-- type End -->
        </div>
      </div>
    </div>

    <!-- Answer Styling -->
    <div class="row my-2 text-setting">
      <div class="col-8 d-flex align-items-center pr-1">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.08333 3.33334V5.83334H6.24999V15.8333H8.74999V5.83334H12.9167V3.33334H2.08333ZM17.9167 7.50001H10.4167V10H12.9167V15.8333H15.4167V10H17.9167V7.50001Z"
            fill="#B3AFB6"
          />
        </svg>

        <select
          class="form-select w-100 form-control-sm input-border-style ml-1"
          aria-label="Default select example"
          v-model="block.style.fontFamily"
        >
          <option
            v-for="font in getFontFamilyList"
            :key="font.value"
            :value="font.value"
          >
            {{ font.value }}
          </option>
        </select>
      </div>
      <div class="col-12 d-flex align-items-center ml-1">
        <div class="d-flex align-items-center">
          <span class="">
            <svg
              width="19"
              height="13"
              viewBox="0 0 19 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.6666 3.825H18.3333L15.8333 0.5L13.3333 3.825H14.9999V9.675H13.3333L15.8333 13L18.3333 9.675H16.6666V3.825Z"
                fill="#B3AFB6"
              />
              <path
                d="M0.833282 3V0.5H11.6666V3H7.49995V13H4.99995V3H0.833282Z"
                fill="#B3AFB6"
              />
            </svg>
          </span>
          <input
            type="number"
            min="0"
            max="100"
            v-model="block.style.fontSize"
            class="form-control-sm input-border-style"
          />
        </div>

        <div class="d-flex align-items-center">
          <span
            class="mx-1"
            :class="{
              'active-svg': block.style.fontWeight === 'bold',
            }"
            @click="
              block.style.fontWeight =
                block.style.fontWeight == 'normal' ? 'bold' : 'normal'
            "
          >
            <svg
              width="10"
              height="12"
              viewBox="0 0 10 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.00001 5.99165C8.80834 5.43331 9.37501 4.51665 9.37501 3.66665C9.37501 1.78331 7.91668 0.333313 6.04168 0.333313H0.833344V12H6.70001C8.44168 12 9.79168 10.5833 9.79168 8.84165C9.79168 7.57498 9.07501 6.49165 8.00001 5.99165ZM3.33334 2.41665H5.83334C6.52501 2.41665 7.08334 2.97498 7.08334 3.66665C7.08334 4.35831 6.52501 4.91665 5.83334 4.91665H3.33334V2.41665ZM6.25001 9.91665H3.33334V7.41665H6.25001C6.94168 7.41665 7.50001 7.97498 7.50001 8.66665C7.50001 9.35831 6.94168 9.91665 6.25001 9.91665Z"
                fill="#B3AFB6"
              />
            </svg>
          </span>
          <span
            class="mx-1"
            :class="{
              'active-svg': block.style.fontStyle === 'italic',
            }"
            @click="
              block.style.fontStyle =
                block.style.fontStyle == 'normal' ? 'italic' : 'normal'
            "
          >
            <svg
              width="10"
              height="12"
              viewBox="0 0 10 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.33333 0.333313V2.83331H5.175L2.325 9.49998H0V12H6.66667V9.49998H4.825L7.675 2.83331H10V0.333313H3.33333Z"
                fill="#B3AFB6"
              />
            </svg>
          </span>
          <span
            class="mx-1"
            :class="{
              'active-svg': block.style.textDecoration === 'underline',
            }"
            @click="
              block.style.textDecoration =
                block.style.textDecoration == 'underline'
                  ? 'unset'
                  : 'underline'
            "
          >
            <svg
              width="12"
              height="16"
              viewBox="0 0 12 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.99999 12.1667C8.75832 12.1667 11 9.925 11 7.16667V0.5H8.91666V7.16667C8.91666 8.775 7.60832 10.0833 5.99999 10.0833C4.39166 10.0833 3.08332 8.775 3.08332 7.16667V0.5H0.99999V7.16667C0.99999 9.925 3.24166 12.1667 5.99999 12.1667ZM0.166656 13.8333V15.5H11.8333V13.8333H0.166656Z"
                fill="#B3AFB6"
              />
            </svg>
          </span>
        </div>
      </div>
    </div>

    <div class="row my-2">
      <div class="col-5 d-flex align-items-center pr-0 px-2">
        <input
          type="color"
          v-model="block.style.color"
          style="min-width: 30px; width: 30px; height: 30px; padding: 0px"
          class="form-control-color form-control border-0"
        />
        <input type="text" class="ColorTextInput" v-model="block.style.color" />
      </div>
      <div class="col-3 d-flex align-items-center px-0">
        <svg
          width="20"
          height="20"
          viewBox="0 0 14 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.7167 5.66665L7.00001 0.958313L2.28334 5.66665C0.983344 6.96665 0.333344 8.69998 0.333344 10.3666C0.333344 12.0333 0.983344 13.7916 2.28334 15.0916C3.58334 16.3916 5.29168 17.05 7.00001 17.05C8.70834 17.05 10.4167 16.3916 11.7167 15.0916C13.0167 13.7916 13.6667 12.0333 13.6667 10.3666C13.6667 8.69998 13.0167 6.96665 11.7167 5.66665ZM2.00001 10.6666C2.00834 8.99998 2.51668 7.94165 3.46668 6.99998L7.00001 3.39165L10.5333 7.04165C11.4833 7.97498 11.9917 8.99998 12 10.6666H2.00001Z"
            fill="#B3AFB6"
          />
        </svg>

        <input
          type="number"
          v-model="block.style.textOpacity"
          max="100"
          min="0"
          class="form-control-sm input-border-style w-100"
        />
      </div>
      <div class="col-4 d-flex align-items-center justify-content-around">
        <span
          :class="{
            'active-svg': block.style.textAlign === 'left',
          }"
          type="button"
          @click="block.style.textAlign = 'left'"
        >
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.22223 9.2222H0.333344V10.7037H9.22223V9.2222ZM9.22223 3.29628H0.333344V4.77776H9.22223V3.29628ZM0.333344 7.74072H13.6667V6.25924H0.333344V7.74072ZM0.333344 13.6666H13.6667V12.1852H0.333344V13.6666ZM0.333344 0.333313V1.81479H13.6667V0.333313H0.333344Z"
              fill="#535355"
            />
          </svg>
        </span>
        <span
          :class="{
            'active-svg': block.style.textAlign === 'center',
          }"
          type="button"
          @click="block.style.textAlign = 'center'"
        >
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.29631 9.2222V10.7037H10.7037V9.2222H3.29631ZM0.333344 13.6666H13.6667V12.1852H0.333344V13.6666ZM0.333344 7.74072H13.6667V6.25924H0.333344V7.74072ZM3.29631 3.29628V4.77776H10.7037V3.29628H3.29631ZM0.333344 0.333313V1.81479H13.6667V0.333313H0.333344Z"
              fill="#B3AFB6"
            />
          </svg>
        </span>
        <span
          :class="{
            'active-svg': block.style.textAlign === 'end',
          }"
          type="button"
          @click="block.style.textAlign = 'end'"
        >
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.29631 9.2222V10.7037H10.7037V9.2222H3.29631ZM0.333344 13.6666H13.6667V12.1852H0.333344V13.6666ZM0.333344 7.74072H13.6667V6.25924H0.333344V7.74072ZM3.29631 3.29628V4.77776H10.7037V3.29628H3.29631ZM0.333344 0.333313V1.81479H13.6667V0.333313H0.333344Z"
              fill="#B3AFB6"
            />
          </svg>
        </span>
      </div>
    </div>
    <!-- TextArea Height start-->
    <div class="row d-none">
      <div class="form-group d-flex align-items-center col-12 pr-0">
        <label for="" class="mb-0 mr-2">Free text height :</label>
        <input
          type="number"
          :min="40"
          v-model="block.style.TextAreaHeight"
          class="form-control-sm w-25 input-border-style"
        />
      </div>
    </div>
    <!-- TextArea Height end-->
    <!-- Stroke -->
    <div class="mb-2">
      <div class="row">
        <div class="col-12">
          <p class="editorStyleTitle mb-0">Stroke:</p>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="d-flex align-items-center mr-5">
          <input
            type="color"
            style="min-width: 30px; width: 30px; height: 30px; padding: 0px"
            v-model="block.style.strokeColor"
            class="form-control-color form-control border-0"
          />
          <input
            type="text"
            class="ColorTextInput input-border-style"
            v-model="block.style.strokeColor"
          />
        </div>
     
        <div class="d-flex align-items-center">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.92494 7.10834L4.74993 5.92501C3.99993 6.89168 3.53327 8.00834 3.3916 9.16668H5.07494C5.1916 8.44168 5.48327 7.73334 5.92494 7.10834ZM5.07494 10.8333H3.3916C3.53327 11.9917 3.9916 13.1083 4.7416 14.075L5.9166 12.8917C5.48327 12.2667 5.1916 11.5667 5.07494 10.8333ZM5.9166 15.2667C6.88327 16.0167 8.00827 16.4667 9.1666 16.6083V14.9167C8.4416 14.7917 7.7416 14.5083 7.1166 14.0583L5.9166 15.2667ZM10.8333 3.39168V0.833344L7.0416 4.62501L10.8333 8.33334V5.07501C13.1999 5.47501 14.9999 7.52501 14.9999 10C14.9999 12.475 13.1999 14.525 10.8333 14.925V16.6083C14.1249 16.2 16.6666 13.4 16.6666 10C16.6666 6.60001 14.1249 3.80001 10.8333 3.39168Z"
              fill="#B3AFB6"
            />
          </svg>

          <input
            type="number"
            min="0"
            v-model="block.style.strokeRotation"
            class="form-control-sm input-border-style ml-2"
            style="width: 60px"
          />
        </div>
      </div>
    </div>

    <!-- border Inputs -->
    <div>
      <div class="row my-2">
        <div class="col-4 d-flex justify-content-between align-items-center">
          <button
            class="btn"
            :class="{
              'active-svg': block.style.borderType === 'border',
            }"
            @click="block.style.borderType = 'border'"
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.5 2.16667V13.8333C0.5 14.75 1.25 15.5 2.16667 15.5H13.8333C14.75 15.5 15.5 14.75 15.5 13.8333V2.16667C15.5 1.25 14.75 0.5 13.8333 0.5H2.16667C1.25 0.5 0.5 1.25 0.5 2.16667ZM13 13.8333H3C2.54167 13.8333 2.16667 13.4583 2.16667 13V3C2.16667 2.54167 2.54167 2.16667 3 2.16667H13C13.4583 2.16667 13.8333 2.54167 13.8333 3V13C13.8333 13.4583 13.4583 13.8333 13 13.8333Z"
                fill="#292929"
              />
            </svg>
          </button>
          <button
            class="btn"
            :class="{
              'active-svg': block.style.borderType === 'border-bottom',
            }"
            @click="block.style.borderType = 'border-bottom'"
          >
            <svg
              width="22"
              height="22"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.5 9.16667H4.16667V10.8333H2.5V9.16667ZM2.5 12.5H4.16667V14.1667H2.5V12.5ZM2.5 15.8333H17.5V17.5H2.5V15.8333ZM15.8333 12.5H17.5V14.1667H15.8333V12.5ZM2.5 5.83333H4.16667V7.5H2.5V5.83333ZM15.8333 9.16667H17.5V10.8333H15.8333V9.16667ZM15.8333 2.5H17.5V4.16667H15.8333V2.5ZM15.8333 5.83333H17.5V7.5H15.8333V5.83333ZM12.5 2.5H14.1667V4.16667H12.5V2.5ZM2.5 2.5H4.16667V4.16667H2.5V2.5ZM5.83333 2.5H7.5V4.16667H5.83333V2.5ZM9.16667 2.5H10.8333V4.16667H9.16667V2.5Z"
                fill="#B3AFB6"
              />
            </svg>
          </button>
        </div>
        <div class="d-flex align-items-center col-4 px-0">
          <svg
            width="22"
            height="22"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="20" height="20" fill="white" />
            <path
              d="M2.5 4.16667V15.8333C2.5 16.75 3.25 17.5 4.16667 17.5H15.8333C16.75 17.5 17.5 16.75 17.5 15.8333V4.16667C17.5 3.25 16.75 2.5 15.8333 2.5H4.16667C3.25 2.5 2.5 3.25 2.5 4.16667ZM15 15.8333H5C4.54167 15.8333 4.16667 15.4583 4.16667 15V5C4.16667 4.54167 4.54167 4.16667 5 4.16667H15C15.4583 4.16667 15.8333 4.54167 15.8333 5V15C15.8333 15.4583 15.4583 15.8333 15 15.8333Z"
              fill="#B3AFB6"
            />
          </svg>

          <input
            type="number"
            min="1"
            max="10"
            v-model="block.style.borderSize"
            class="input-border-style ml-2"
            style="max-width: 50px"
          />
        </div>
        <div class="d-flex align-items-center col-4 px-0">
          <svg
            width="22"
            height="22"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.8333 15.8333H17.5V17.5H15.8333V15.8333ZM15.8333 14.1667H17.5V12.5H15.8333V14.1667ZM2.5 10.8333H4.16667V9.16667H2.5V10.8333ZM2.5 14.1667H4.16667V12.5H2.5V14.1667ZM2.5 7.5H4.16667V5.83333H2.5V7.5ZM2.5 4.16667H4.16667V2.5H2.5V4.16667ZM5.83333 4.16667H7.5V2.5H5.83333V4.16667ZM12.5 17.5H14.1667V15.8333H12.5V17.5ZM9.16667 17.5H10.8333V15.8333H9.16667V17.5ZM12.5 17.5H14.1667V15.8333H12.5V17.5ZM5.83333 17.5H7.5V15.8333H5.83333V17.5ZM2.5 17.5H4.16667V15.8333H2.5V17.5ZM17.5 6.66667C17.5 4.36667 15.6333 2.5 13.3333 2.5H9.16667V4.16667H13.3333C14.7083 4.16667 15.8333 5.29167 15.8333 6.66667V10.8333H17.5V6.66667Z"
              fill="#B3AFB6"
            />
          </svg>

          <input
            type="number"
            min="0"
            v-model="block.style.borderRadius"
            class="input-border-style ml-2"
            style="max-width: 50px"
          />
        </div>
      </div>
    </div>

    <!-- Answer options -->
    <div>
      <div class="mt-3">
        <div class="form-group d-flex mb-2 align-items-center row">
          <div class="col-12">
            <span class="editorStyleTitle"> Sample text:</span>
          </div>
          <div class="col-12 px-0 mt-2">
            <div class="mx-3">
              <input
                type="text"
                class="py-1 w-100 form-control-input"
                style="
                  border: none;
                  border-radius: 2px;
                  outline: none;
                  border-bottom: 1px solid #e0dee2;
                "
                v-model="block.InfoTypePlaceholder"
                placeholder="Input Placeholder"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Margin -->

    <div class="row m-0" v-if="isDesktopView">
      <h4 class="editorStyleTitle mt-4">Margins:</h4>
      <div class="row align-items-center">
        <div class="col-4 d-flex">
          <div class="row align-items-center">
            <div class="col-4">
              <svg
                width="14"
                height="16"
                viewBox="0 0 14 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.3334 7.16667H7.83337V15.5H6.16671V7.16667H3.66671L7.00004 3.83333L10.3334 7.16667ZM0.333374 2.16667V0.5H13.6667V2.16667H0.333374Z"
                  fill="#B3AFB6"
                />
              </svg>
            </div>
            <div class="col-8">
              <input
                type="number"
                v-model="block.style.marginTop"
                class="form-control-sm w-75 px-2 input-border-style"
              />
            </div>
          </div>
        </div>
        <div class="col-4 d-flex">
          <div class="row align-items-center">
            <div class="col-4">
              <svg
                width="14"
                height="16"
                viewBox="0 0 14 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.3334 8.83333H7.83337V0.5H6.16671V8.83333H3.66671L7.00004 12.1667L10.3334 8.83333ZM0.333374 13.8333V15.5H13.6667V13.8333H0.333374Z"
                  fill="#B3AFB6"
                />
              </svg>
            </div>
            <div class="col-8">
              <input
                type="number"
                v-model="block.style.marginBottom"
                class="form-control-sm w-75 px-2 input-border-style"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="form-group col-4 d-flex">
          <div class="row align-items-center">
            <div class="col-4">
              <svg
                width="16"
                height="14"
                viewBox="0 0 16 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.16667 3.66663V6.16663L15.5 6.16663V7.83329L7.16667 7.83329V10.3333L3.83333 6.99996L7.16667 3.66663ZM2.16667 13.6666L0.5 13.6666V0.333294L2.16667 0.333294V13.6666Z"
                  fill="#B3AFB6"
                />
              </svg>
            </div>
            <div class="col-8">
              <input
                type="number"
                v-model="block.style.marginLeft"
                class="form-control-sm w-75 px-2 input-border-style"
              />
            </div>
          </div>
        </div>
        <div class="form-group col-4 d-flex">
          <div class="row align-items-center">
            <div class="col-4">
              <svg
                width="16"
                height="14"
                viewBox="0 0 16 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.83333 3.66663V6.16663L0.5 6.16663L0.5 7.83329L8.83333 7.83329V10.3333L12.1667 6.99996L8.83333 3.66663ZM13.8333 13.6666L15.5 13.6666V0.333294L13.8333 0.333294V13.6666Z"
                  fill="#B3AFB6"
                />
              </svg>
            </div>
            <div class="col-8">
              <input
                type="number"
                v-model="block.style.marginRight"
                class="form-control-sm w-75 px-2 input-border-style"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-2" v-else>
      <h4 class="editorStyleTitle mt-4">Mobile margin:</h4>
      <div class="row m-0">
        <div class="row align-items-center">
          <div class="col-4 d-flex">
            <div class="row align-items-center">
              <div class="col-4">
                <svg
                  width="14"
                  height="16"
                  viewBox="0 0 14 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.3334 7.16667H7.83337V15.5H6.16671V7.16667H3.66671L7.00004 3.83333L10.3334 7.16667ZM0.333374 2.16667V0.5H13.6667V2.16667H0.333374Z"
                    fill="#B3AFB6"
                  />
                </svg>
              </div>
              <div class="col-8">
                <input
                  type="number"
                  min="0"
                  placeholder="Top"
                  v-model="block.style.mobileMarginTop"
                  class="form-control-sm w-75 px-2 input-border-style"
                />
              </div>
            </div>
          </div>
          <div class="col-4 d-flex">
            <div class="row align-items-center">
              <div class="col-4">
                <svg
                  width="14"
                  height="16"
                  viewBox="0 0 14 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.3334 8.83333H7.83337V0.5H6.16671V8.83333H3.66671L7.00004 12.1667L10.3334 8.83333ZM0.333374 13.8333V15.5H13.6667V13.8333H0.333374Z"
                    fill="#B3AFB6"
                  />
                </svg>
              </div>
              <div class="col-8">
                <input
                  type="number"
                  min="0"
                  placeholder="Bottom"
                  v-model="block.style.mobileMarginBottom"
                  class="form-control-sm w-75 px-2 input-border-style"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="form-group col-4 d-flex">
            <div class="row align-items-center">
              <div class="col-4">
                <svg
                  width="16"
                  height="14"
                  viewBox="0 0 16 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.16667 3.66663V6.16663L15.5 6.16663V7.83329L7.16667 7.83329V10.3333L3.83333 6.99996L7.16667 3.66663ZM2.16667 13.6666L0.5 13.6666V0.333294L2.16667 0.333294V13.6666Z"
                    fill="#B3AFB6"
                  />
                </svg>
              </div>
              <div class="col-8">
                <input
                  type="number"
                  v-model="block.style.mobileMarginLeft"
                  min="0"
                  placeholder="Left"
                  class="form-control-sm w-75 px-2 input-border-style"
                />
              </div>
            </div>
          </div>
          <div class="form-group col-4 d-flex">
            <div class="row align-items-center">
              <div class="col-4">
                <svg
                  width="16"
                  height="14"
                  viewBox="0 0 16 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.83333 3.66663V6.16663L0.5 6.16663L0.5 7.83329L8.83333 7.83329V10.3333L12.1667 6.99996L8.83333 3.66663ZM13.8333 13.6666L15.5 13.6666V0.333294L13.8333 0.333294V13.6666Z"
                    fill="#B3AFB6"
                  />
                </svg>
              </div>
              <div class="col-8">
                <input
                  type="number"
                  v-model="block.style.mobileMarginRight"
                  min="0"
                  placeholder="Right"
                  class="form-control-sm w-75 px-2 input-border-style"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import fontFamilyList from "../../../staticPages/fontFamilyList";
export default {
  props: {
    block: Object,
    isDesktopView: Boolean,
  },
  computed: {
    getFontFamilyList() {
      return fontFamilyList;
    },
    textBlockFont(){
      return this.block.style.fontFamily
    },
  },
  watch:{
    textBlockFont(newVal,oldVal){
      if(oldVal && newVal){
        this.$store.dispatch('updateFontFamily', { oldFontFamily:oldVal, newFontFamily:newVal });
        this.$store.dispatch('loadFontsFromTracker');
      }
    }
  }
};
</script>
<style scoped>
.ql-container.ql-snow {
  background: white;
}
.form-control-sn {
  border: 1px solid;
  width: 100%;
  border-radius: 3px;
  /* line-height: 1rem; */
  height: 26px;
  font-size: 14px;
}

.active-svg {
  padding: 3px;
  background: #b3afb6;
  border-radius: 3px;
}
.active-svg path {
  fill: #323232;
}
</style>
